var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.$options.name},[_c('el-dialog',{attrs:{"title":_vm.option.name,"visible":_vm.dialogVisible,"width":"600px","before-close":_vm.handleClose},on:{"update:visible":function($event){_vm.dialogVisible=$event}}},[_c('div',{staticClass:"cropper-container"},[_c('div',{staticClass:"cropper-el"},[_c('vue-cropper',{ref:"cropper",attrs:{"img":_vm.cropperImg,"output-size":_vm.option.size,"output-type":_vm.option.outputType,"info":true,"full":_vm.option.full,"can-move":_vm.option.canMove,"can-move-box":_vm.option.canMoveBox,"fixed-box":_vm.option.fixedBox,"original":_vm.option.original,"auto-crop":_vm.option.autoCrop,"auto-crop-width":_vm.option.autoCropWidth,"auto-crop-height":_vm.option.autoCropHeight,"center-box":_vm.option.centerBox,"high":_vm.option.high,"info-true":_vm.option.infoTrue,"enlarge":_vm.option.enlarge,"fixed":_vm.option.fixed,"fixed-number":_vm.option.fixedNumber},on:{"realTime":_vm.realTime}})],1),_c('div',{staticClass:"prive-el"},[_c('div',{staticClass:"prive-style",style:({
            width: '200px',
            height: '200px',
            overflow: 'hidden',
            margin: '0 25px',
            display: 'flex',
            'align-items': 'center'
          })},[_c('div',{staticClass:"preview",style:(_vm.previews.div)},[_c('img',{style:(_vm.previews.img),attrs:{"src":_vm.previews.url}})])]),(_vm.option.img)?_c('el-button',{on:{"click":_vm.uploadBth}},[_vm._v("重新上传")]):_vm._e()],1)]),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{on:{"click":_vm.handleClose}},[_vm._v("取 消")]),_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.saveImg}},[_vm._v("确 定")])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }