<template>

  <div class="register">

    <div class="site-content">

       <div class="content-warp">

            <el-steps  finish-status="success" :active="active">

                <el-step title="步骤 1" icon="el-icon-help"></el-step>

                <el-step title="步骤 2" icon="el-icon-picture-outline-round"description="注册成功"></el-step>

            </el-steps>

       </div>

       <div class="Main"  v-loading="loading">

            <h3 class="title">注册</h3>

            <el-row :gutter="20">

               <el-col :span="23">
                   
                   <el-form class="login-form" ref="ruleForm" :model="ruleForm"  :rules="rules"  label-width="90px"  autocomplete="off" >

                        <el-form-item label="用户名" prop="name">

                            <el-input  show-word-limit  maxlength="10" suffix-icon="el-icon-star-off"  v-model="ruleForm.name" />

                        </el-form-item>

                        <el-form-item label="邮箱" prop="email">

                            <el-row :gutter="0">

                              <el-col :span="12">

                                <el-input   show-word-limit suffix-icon="el-icon-star-off" v-model="ruleForm.email" />

                              </el-col>

                              <el-col :span="8">

                                <el-button size="medium" :disabled="Senddisabled" round  @click="sendMsg">发送验证码</el-button>  

                                <span class="status">{{ statusMsg }}</span>

                              </el-col>

                            </el-row>
                             
                        </el-form-item>

                        <el-form-item label="验证码" prop="code">

                            <el-input    show-word-limit maxlength="6" v-model="ruleForm.code" />
                            
                        </el-form-item>

                        
                        <el-form-item label="密码" prop="pwd">

                            <el-input    show-word-limit  maxlength="10" v-model="ruleForm.pwd" type="password" />

                        </el-form-item>

                        <el-form-item label="确认密码" prop="cpwd">
 
                            <el-input   show-word-limit   maxlength="10" v-model="ruleForm.cpwd" type="password" />

                        </el-form-item>

                         <el-form-item label="个人简介">
 
                            <el-input   show-word-limit :autosize="{ minRows: 4, maxRows: 4}" resize="none"  maxlength="100" v-model="ruleForm.Introduction"   type="textarea" />

                        </el-form-item>

                        <el-form-item label="用户头像">

                            <div class="uploadimage">

                                <el-upload  ref="upload" action="#" 
                        
                                  accept="image/png, image/gif, image/jpg, image/jpeg" list-type="picture-card":limit="1"
                                  
                                  :file-list="fileList"

                                  :auto-upload="false"

                                  :on-exceed="handleExceed"

                                  :on-change="beforeAvatarUpload"

                                  :on-remove="handleRemove"
                                  
                                  > <i class="el-icon-plus"></i>
                        
                                </el-upload>
                            </div>

                        </el-form-item>

                   </el-form>

               </el-col>

            </el-row>

            <el-row :gutter="20">

                <el-divider></el-divider>

                <el-button  size="medium" type="danger" @click="RegisteredSave" >确定</el-button>

                <el-button  size="medium" type="danger" @click="close" >取消</el-button>

            </el-row>
                    
       </div>

       <cropper v-if="dialogVisible"  :dialog-visible="dialogVisible" :cropper-img="cropperImg" @upload_img="upload_img"  @colse-dialog="closeDialog" />

    </div>
 
  </div>

</template>

<script>

import {SendEmail,Registered}   from "../api/Resources" 

import { VueCropper } from "vue-cropper";

import Banner from "@/components/banner";

import Cropper from '@/components/cropper';

export default {


  name: "register",

   components: {

    Banner,

    VueCropper,

    Cropper

   
  },

  data(){
    return {

        active:0,

        loading:false,

        statusMsg:"",

        fileList: [],

        timerid :"",

        cropperImg:"",

        Senddisabled:false,

        dialogVisible:false,
        
        ruleForm: {

            code: '',

            key:'',

            name:'',

            pwd: '',

            cpwd: '',

            email: '',

            Introduction:"",
      },

      rules: {

        name: [{

          required: true,

          type: 'string',

          message: '请输入用户名',

          trigger: 'blur'

        }],

        code: [{

          required: true,

          type: 'string',

          message: '验证码不能为空',

          trigger: 'blur'
          
        }],
       
        email: [{

          required: true,

          type: 'email',

          message: '请输入邮箱',

          trigger: 'blur'

        }],
        pwd: [{

          required: true,

          message: '创建密码',

          trigger: 'blur'

        }],
        cpwd: [{

          required: true,

          message: '确认密码',

          trigger: 'blur'

        }, {
          validator: (rule, value, callback) => {

            if (value === '') {

              callback(new Error('请再次输入密码'))

            } else if (value !== this.ruleForm.pwd) {

              callback(new Error('两次输入密码不一致'))

            } else {

              callback()
            }
          },
          trigger: 'blur'
        }]
      }
        
    }
  },

  methods: {

    /**
     * 下一步
     */
    next() {

      if (this.active === 0) {

        this.$refs['ruleForm'].validateField('agreed', (valid) => {

          if (valid === '') {

            this.active++

          }

        })
      }
    },

    /**
     * 上一步
     */
    prev() {if (--this.active < 0) this.active = 0 },

    close(){this.$router.push('/');},

    beforeAvatarUpload(file, fileList) {

      const isImage = file.raw.type == "image/png" || file.raw.type == "image/jpg" || file.raw.type == "image/jpeg";

      const isLt5M = file.size < 1024 * 1024 * 5;

      if (!isImage) {this.$message.error("上传只能是png,jpg,jpeg格式!"); fileList.splice(-1, 1);}


      if (!isLt5M) { this.$message.error("上传图片大小不能超过 5MB!"); fileList.splice(-1, 1); }

      this.fileList = fileList;
    },

    handleExceed(files, fileList) {this.$message.warning("当前限制选择1个文件"); },

    handleRemove(file, fileList) { this.fileList = fileList;  },

    sendMsg(){

        const self = this;

        let emailPass

        this.$refs['ruleForm'].validateField('email', (valid) => {emailPass = valid  })

        self.statusMsg = ''

        if(emailPass)
        {
          this.$message.error('错了哦，这是一条错误消息');

          return;
        }
        
        self.Senddisabled=true;

        let count = 60

        SendEmail(self.ruleForm.email).then(res=>{

            self.ruleForm.key=res.rspdata.key;

            self.$message({message: '发送验证码成功', type: 'success' });

            self.statusMsg = `${count--}`

            self.timerid = setInterval(function() {

            self.statusMsg = `${count--}`

            if (count === 0) {
                
              clearInterval(self.timerid);

              self.statusMsg ="";

              self.ruleForm.key="";
              
              self.Senddisabled=false;

              this.$message.error('验证码已过期,重新获取'); 
            }

            }, 1000)
        }).catch(err => {this.$message.error('服务器已断开,请联系管理员');  self.Senddisabled=false; })
    
    },

    /**
     * 注册普通用户
     */
    RegisteredSave(){

      // 更新进度
       const progressFun = () => {

            this.loading=true;

            const formData = new FormData();

            this.fileList.forEach(element => {if (element.raw != undefined) { formData.append("file", element.raw);} });

            formData.append("Email",this.ruleForm.email);

            formData.append("UserName",this.ruleForm.name);

            formData.append("UserPassword",this.ruleForm.pwd);

            formData.append("UsercPassword",this.ruleForm.cpwd);

            formData.append("Code",this.ruleForm.code);

            formData.append("Introduction",this.ruleForm.Introduction);

            formData.append("Key",this.ruleForm.key);

            Registered(formData).then(res=>{

                this.$message({message: '注册成功', type: 'success' });

                this.loading=false;

                this.active=1;

                this.close(); 

            }).catch(erro=>{ this.loading=false; console.log(erro)})
        }

        var submit= this.$refs["ruleForm"].validate((valid) => 
        {
            if (valid) 
            {
               this.$confirm('是否头像裁剪, 是否继续?', '提示', {confirmButtonText: '确定',cancelButtonText: '取消', type: 'warning' }).then(() => {

                 this.handlePictureCardPreview(this.fileList[0]);  return true; 
               
                }).catch(() => {

                    progressFun();

                    return true; 
                    
                });

                  
            } 
            else {

                this.$message.error('错了哦，这是一条错误消息')

                return false;
            }
        });
    },

    /**
     * 图片预览
     */

    handlePictureCardPreview(file){

      let reader = new FileReader();

      reader.readAsDataURL(file.raw)

       reader.onload = e => {

            let data;

            if (typeof e.target.result === 'object') {
              // 把Array Buffer转化为blob 如果是base64不需要
              data = window.URL.createObjectURL(new Blob([e.target.result]));
            } else {
              data = e.target.result;
            }

            this.cropperImg=data;
  
            this.dialogVisible = true;

        }
    },
     // 关闭窗口
    closeDialog () {
      this.dialogVisible = false;
    },
    upload_img(data)
    {
       // 更新进度
       const progressFun = () => {

            this.loading=true;


            const formData = new FormData();

            formData.append("file", data);

            formData.append("Email",this.ruleForm.email);

            formData.append("UserName",this.ruleForm.name);

            formData.append("UserPassword",this.ruleForm.pwd);

            formData.append("UsercPassword",this.ruleForm.cpwd);

            formData.append("Code",this.ruleForm.code);

            formData.append("Introduction",this.ruleForm.Introduction);

            formData.append("Key",this.ruleForm.key);

            Registered(formData).then(res=>{

                this.$message({message: '注册成功', type: 'success' });

                this.loading=false;

                this.active=1;

                this.close(); 

            }).catch(erro=>{ this.loading=false; console.log(erro)})
        }

        progressFun();

        this.closeDialog();
    }


  },
  mounted() {}
};
</script>



<style scoped lang="less">

.site-content{

 .content-warp {
      
    margin-top: 140px;

    width: 100%;

  }

  // 截图
  .cropper-content {
      .cropper {
          width: auto;
          height: 300px;
      }
}

  .Main{

        width: 65%;

        border-radius:30px;

        border: 1px solid rgba(0,0,0,.1);

        box-shadow: 0 1px 3px rgba(0,0,0,.3);

        margin: 20px auto 50px ;

        padding: 15px;

        max-height: 1500px;

        box-sizing: border-box;

        text-align: center;

        .title {

            margin: 0 auto ;

            text-align: center;

            color: #707070;

            font-weight: bold;

            font-size:25px; 
        }

        .login-form {

            padding-top: 15px;

            margin: 0 auto ;

            .el-input {

                font-size:16px;

            }
          
            .input-icon {

                height: 39px;

                width: 14px;

                margin-left: 2px;

            }

            .status {

                font-size: 15px;

                margin-left: 10px;

                font-weight: bold;

                color: red;
            }

            .uploadimage{

                width: 100%;

                min-height: 80px;

                max-height: 500px;
        
                float: left;

                div{
                    float: left;

                    margin: 0 auto;
                }

            }

        }

    }
}

</style>
